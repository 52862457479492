import {
  Component,
  ElementRef,
  Host,
  Input,
  Optional,
  SkipSelf,
  ViewChild,
  forwardRef,
} from "@angular/core";
import {
  ControlContainer,
  ControlValueAccessor,
  FormArray,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { BaseForm } from "@app/admin/base/form-base";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { ApiService } from "@services/api.service";
import { InputHelper } from "@services/input-helper";
import { FormCarrierCost } from "@wearewarp/ng-antd";
import { StringULID } from "@wearewarp/types";
import { CarrierCost } from "@wearewarp/types/data-model/types/AssignedCarrier";
import { Observable, of } from "rxjs";
import { Log } from "@services/log";

interface FormDataCarrierDedicatedInfo {
  clientIds: StringULID[],
  lanes: {from: string, to: string}[],
  daysOfWeek: number[],
  carrierCost?: CarrierCost,
}

@Component({
  selector: '[form-carrier-pool-dedicated-info]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormCarrierDedicatedInfo)
    }
  ]
})
export class FormCarrierDedicatedInfo extends BaseForm<FormDataCarrierDedicatedInfo> implements ControlValueAccessor {
  @Input() formGroupName: string;
  @Input() isPriceAvailable: boolean = false;
  @Input() poolId: StringULID = '';

  constructor(@Optional() @Host() @SkipSelf() private parent: ControlContainer) {
    super();
  }

  ngOnInit(): void {
    this.formInput = <FormGroup>this.parent?.control?.get?.(this.formGroupName);
    super.ngOnInit();
    let _daysOfWeek = this.formInput.get('daysOfWeek').value ?? []
    this.daysOfWeek = [...this.daysOfWeek.map(d => ({...d, checked: _daysOfWeek.includes(d.value)}))]
    this.fetchListCarrierSales();
  }

  public api;
  public listCarrierSales = [];
  public isFetchingCarrierSaleRep = false;
  private fetchListCarrierSales(silent: boolean = false) {
    this.isFetchingCarrierSaleRep = true;
    ApiService.instance.getListAdminUsers().subscribe(
      resp => {
        this.listCarrierSales = resp?.data?.list_data ?? [];
        this.isFetchingCarrierSaleRep = false;
      }, err => {
        if (silent) {
          // Nếu silent thì không bắn lỗi lên màn hình.
          Log.e(err);
        } else {
          console.error(err);
        }
        this.isFetchingCarrierSaleRep = false;
      }
    );
  }

  public getFullName(user) {
    if (!user) return '';
    if (user.fullName) return user.fullName;
    let text = user.firstName || '';
    if (user.lastName) {
      if (text) {
        text += ' ';
      }
      text += user.lastName;
    }
    return text;
  }

  public static get declaration(): FormGroupDeclaration {return {
    clientIds: {label: 'Select Customer', required: false},
    carrierSalesRepId: {
      label: "Carrier Sales Rep",
      placeHolder: "Carrier Sales Rep",
      required: true
    },
    lanes: { label: '', required: true, type: 'formArray', childItem: {
      from: {label: 'From Zipcode'},
      to: {label: 'To Zipcode'},
    }, initialValue: [{}]},
    daysOfWeek: {label: 'Days of week', required: false, initialValue: [0, 1, 2, 3, 4, 5, 6]},
    baseRate: {label: '', type: 'formGroup', childItem: FormCarrierCost.makeDeclaration()},
  }}
  protected formGroupDeclaration: FormGroupDeclaration = FormCarrierDedicatedInfo.declaration;

  public listAddressAutoComplete: any = {};
  public daysOfWeek = [
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 },
    { label: 'Saturday', value: 6 },
    { label: 'Sunday', value: 0 }
  ]

  onCustomerChange(event) {
    // TODO
  }

  onFocusCustomerFilter() {
    // TODO
  }

  saveCarrierCost = (jobId: string, data: CarrierCost): Observable<any> => {
    this.setItemValue('baseRate', data);
    if (this.poolId) {
      const url = Const.APIV2(`${Const.APIURI_POOLS}/${this.poolId}/update-base-rate`);
      return ApiService.instance.POST(url, data);
    } else {
      // Đây là trường hợp tạo mới, chỉ cần lưu data trong form là được
    }
    return of(null);
  }

  saveCarrierCostSuccess = () => {
    // do nothing
  }

  // ControlValueAccessor implementation
  protected onChange: Function = () => { };
  protected onTouch: Function = () => { };
  writeValue(v: FormDataCarrierDedicatedInfo): void {
    this.model = v;
    this.onChange(v);
    this.onTouch(v);
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouch = fn;
  }
  
  changeDaysOfWeek(e = []){
    let days = e.filter(i => i.checked).map(i => i.value)
    this.formInput.get('daysOfWeek').setValue([...days])
  }

  @ViewChild('laneInputFrom') laneInputFrom: ElementRef<HTMLInputElement>;
  @ViewChild('laneInputTo') laneInputTo: ElementRef<HTMLInputElement>;

  canAddLane(): boolean {
    return this.laneInputFrom?.nativeElement.value.trim().length > 0 && this.laneInputTo?.nativeElement.value.trim().length > 0;
  }

  onBtnAddLane() {
    if (!this.canAddLane()) return;
    const data = {
      from: this.laneInputTo.nativeElement.value.trim(),
      to: '',
    };
    const key = 'lanes';
    this.addItemToFormArray(key, data);
    this.laneInputFrom.nativeElement.focus();
  }

  onBtnRemoveLane(index) {
    const key = 'lanes';
    let fg = (<FormArray>this.formInput.get(key)).at(index)
    let from = fg.get('from').value;
    let to = fg.get('to').value;
    DialogService.confirmDeletion({
      message: `Remove zipcode <b>[${from} → ${to}]</b>?`,
      fnOk: () => {
        this.removeItemInFormArray(key, index);
      }
    })

  }

  onInputKeyUp(event) {
    if (!InputHelper.isEnter(event)) {
      return;
    }
    if (event.target == this.laneInputFrom?.nativeElement) {
      this.laneInputTo?.nativeElement.focus();
    } else if (event.target == this.laneInputTo?.nativeElement) {
      this.onBtnAddLane();
    }
  }
}