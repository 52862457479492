<form [formGroup]="formInput" nz-form *ngIf="formInput">
  <ng-container *ngIf="'clientIds' as key">
    <div>{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
    <div style="display: flex;">
      <select-by-searching style="min-width: 400px;" [version]="2" [formControlName]="key"
        (modelChange)="onCustomerChange($event)" (onFocus)="onFocusCustomerFilter()" [placeholder]="getPlaceHolder(key)"
        dropdownClassName="carrier-pool-dedicated-customers">
      </select-by-searching>
    </div>
  </ng-container>

  <ng-container *ngIf="'carrierSalesRepId' as key">
    <div class="top20">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
    <div style="display: flex;">
      <nz-select nzBackdrop="true" nzAllowClear nzShowSearch
        [formControlName]="key" [nzLoading]="isFetchingCarrierSaleRep"
        [nzPlaceHolder]="getPlaceHolder(key)"
        style="min-width: 400px;"
      >
        <nz-option *ngFor="let item of listCarrierSales"
          [nzValue]="item.id" [nzLabel]="getFullName(item)">
        </nz-option>
      </nz-select>
    </div>
  </ng-container>

  <div>
    <div class="top20">Select lane</div>
    <div class="warning" *ngIf="getArrayControls('lanes').length > 1">Please enter zipcodes in stops order</div>
    <ng-container [formArrayName]="'lanes'">
      <form *ngFor="let item of getArrayControls('lanes'); let index = index" nz-form [formGroupName]="index">
        <div nz-row class="flex1" style="max-width: 920px; padding-bottom: 12px">
          <div nz-col nzSpan="9">
            <div class="lane-input-label">From Zipcode</div>
            <input nz-input #laneInputFrom
              formControlName="from" 
              (keyup)="onInputKeyUp($event)"
            />
          </div>
          <div nz-col nzSpan="2" style="display: flex; justify-content: center;">
            <i nz-icon nzType="arrow-right" nzTheme="outline" style="margin-top: 29px;"></i>
          </div>
          <div nz-col nzSpan="9">
            <div class="lane-input-label">To Zipcode</div>
            <input nz-input #laneInputTo
              formControlName="to"           
              (keyup)="onInputKeyUp($event)"
            />
          </div>
          <div nz-col nzSpan="4">
            <div class="lane-input-label">&nbsp;</div>
            <div class="group-button">
              <div class="link primary btn-add" (click)="onBtnAddLane()">Add</div>  
              <div *ngIf="getArrayControls('lanes').length > 1" class="link danger btn-remove" (click)="onBtnRemoveLane(index)">Remove</div>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
 
</form>
<div>
  <div nz-row class="top20">Days of week</div>
  <nz-checkbox-group [(ngModel)]="daysOfWeek" (ngModelChange)="changeDaysOfWeek($event)"></nz-checkbox-group>
</div>

<div style="max-width: 770px;">
  <div nz-row class="top20 price-label">
    Base price
    <i class="grey">(only available if there is 1 equipment)</i>
  </div>
  <div display-carrier-cost [jobId]="'no_job'" 
    [isReadOnly]="!isPriceAvailable" 
    [carrierCost]=" getFormData()?.baseRate"
    [saveCarrierCost]="saveCarrierCost" 
    [saveCarrierCostSuccess]="saveCarrierCostSuccess"></div>
</div>