import { Component, Input, NgZone, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ExtendValidators } from "@app/admin/base/validator";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import _ from "underscore";
import { Utils } from '@services/utils';
import { MasterData } from '@services/master.data';
import { BaseDetail } from "@app/admin/base/detail";
import { ActivatedRoute } from "@angular/router";
import { DialogService } from "@dialogs/dialog.service";
import { ServiceArea } from "@app/admin/components/service-area";
import { FormCarrierDedicatedInfo } from "../components/dedicated-info";
const API_URLS = {
  SUBMIT_POOLS: `${Const.APIV2(Const.APIURI_POOLS)}`,
};
@Component({
  selector: "app-create-pool",
  templateUrl: "./create.component.html",
  styleUrls: ["./create.component.scss"],
})
export class CreatePoolComponent extends BaseDetail {
  @Input() subjectType;
  @ViewChild('transfer_carrier') transfer_carrier;
  private _editId: number;
  public get editId() { return this._editId }
  public isError = false;
  public isLoading = false;
  public matchedCarriers: string[] = [];
  public isAutoLoading = false;

  protected formGroupDeclaration: FormGroupDeclaration = {
    name: {
      label: "Pool Name",
      placeHolder: "Pool Name",
      required: true
    },
    poolType: {
      label: "Pool Type",
      initialValue: 'normal'
    },
    subjectType: {
      label: "Subject",
      placeHolder: "Subject",
      required: true,
    },

    description: {
      label: "Description",
      placeHolder: "Description",
    },
    equipment: {
      label: "Equipment",
      placeHolder: "Equipment",
    },
    subjectIds: {
      required: true,
      label: "Subject",
    },
    coverageAreas: {
      required: false,
      label: "Service Coverage Area"
    },
    vehicleTypes: {
      required: false,
      label: "Equipments"
    },
    options: {
      required: false,
      label: "Option"
    },
    autoAddCarrier: {
      required: false,
      label: "Auto Add Carrier"
    },
    isDedicatedPool: {
      label: "Dedicated Pool"
    },
    isAutoSendBid: {
      label: 'Auto Send Bid'
    }
  };
  vehicleTypes = [];
  vehicleSelectOptions = []
  public get areaSelectedList() {
    return this.formInput.get('coverageAreas').value || [];
  }

  private validators = {
    name: [
      ExtendValidators.required("Pool name is required!"),
    ],
    subjectType: [
      ExtendValidators.required("Subject Type is required!"),
    ],
    subjectIds: [ExtendValidators.required("Choose at least one!")],
  };

  constructor(activatedRoute: ActivatedRoute, api: ApiService, private ngZone: NgZone) {
    super(activatedRoute);
    this.api = api;
    this.subjectType = this.getRouteParam(this.activatedRoute, "subjectType");
    this._editId = this.getRouteParam(this.activatedRoute, "id");
  }

  get shouldCreateFormImmediately(): boolean {
    return this.isCreateNew;
  }

  get needUpdate(): boolean {
    return super.needUpdate;
  }

  get isCreateNew(): boolean {
    return this.editId ? false : true;
  }

  get isDedicatedEnabled(): boolean {
    return this.isFormControlExists('dedicatedInfo');
  }

  get poolType() {
    return this.formInput.get('poolType').value
  }

  get isPriceAvailable(): boolean {
    return (this.formInput.get('vehicleTypes').value ?? []).length <= 1;
  }

  private allStatesCode = [];
  ngOnInit(): void {
    super.ngOnInit();
    let allStates = MasterData.getStatesUS();
    for (let item of allStates) {
      this.allStatesCode[item.code.toUpperCase()] = item;
    }
    this.getDataForEdit();
  }

  protected afterBindModel(model?: any): void {
    this.setLanesRequire()
    super.afterBindModel(model)
  }

  getApiUrl() {
    return ""
  }

  onDeletedSelectedItem(value) {
    const data = this.areaSelectedList || [];
    const newValue = data.filter(item => item.data.id != value.data.id);
    this.formInput.get('coverageAreas').setValue(newValue);
  }

  onChangeVehicleTypes(value) {
    this.formInput.get('vehicleTypes').setValue(value);
  }

  onOptionChange(type, event) {
    if (event) {
      if (this.vehicleSelectOptions.indexOf(type) < 0) this.vehicleSelectOptions.push(type)
    } else {
      this.vehicleSelectOptions = this.vehicleSelectOptions.filter(it => it !== type)
    }
    this.formInput.get('options').setValue(this.vehicleSelectOptions);
  }

  setLanesRequire(){
    let areas = this.formInput.get('coverageAreas').value;
    let existForm = this.isFormControlExists('dedicatedInfo');
    if(!existForm) return;
    let fg = <FormGroup>this.formInput.get('dedicatedInfo');
    // let fromZipFc = <FormArray>fg.get('lanes');
    // let toZipFc = <FormControl>fg.get('toZipcode');
    // if(areas?.length){
    //   fromZipFc.removeValidators(Validators.required);
    //   toZipFc.removeValidators(Validators.required);
    // } else {
    //   fromZipFc.addValidators(Validators.required);
    //   toZipFc.addValidators(Validators.required);
    // }
    // fromZipFc.updateValueAndValidity();
    // toZipFc.updateValueAndValidity();
  }

  onBtnAddArea() {
    DialogService.openDialog(ServiceArea, {
      nzComponentParams: {
        model: [...this.areaSelectedList],
        onSave: (data) => {
          this.formInput.get('coverageAreas').setValue(data);
          if (this.isCreateNew) {
            let auto = this.formInput.get('autoAddCarrier').value
            if (auto) {
              this.onChangeAutoAddCarrier(auto)
            }
          }
          if (this.isEditing) {
            this.saveServiceArea(data)
          }
          this.setLanesRequire()
        },
      },
      nzClassName: "modal-fullscreen",
      nzCentered: true,
      nzTitle: null,
      nzClosable: true,
    });
  }

  saveServiceArea(data) {
    this.api.POST(`${API_URLS.SUBMIT_POOLS}/${this.editId}/update-service-area`, { coverageAreas: data }).subscribe(
      (response) => {
        this.showSuccess('Service Area has been created successfully.')
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

  protected createFormInput(bindData = undefined) {
    super.createFormInput({
      ...bindData,
      subjectType: this.subjectType
    });
    this.onInitValidator();
  }

  // init các validator vì base không có message, cũng không có params options để khai báo async validator.
  // Chỉ gọi hàm này sau khi tạo form.
  onInitValidator() {
    for (let controlKey in this.validators) {
      const validators: ValidatorFn | ValidatorFn[] = this.validators[controlKey];
      this.getItemByKey(controlKey).addValidators(validators);
    }
  }

  initPoolType(data){
    if(data.isDedicatedPool){
      data.poolType = 'dedicated'
    } else if(data.autoAddCarrier){
      data.poolType = 'autoPool'
    } else {
      data.poolType = 'normal'
    }
    return data;
  }

  formatDedicatedInfo(data){
    if(!data.isDedicatedPool && !data?.dedicatedInfo) return data;
    if(Array.isArray(data?.dedicatedInfo?.clientIds)){
      data.dedicatedInfo.clientIds = data?.dedicatedInfo?.clientIds?.[0]
    }
    return data
  }

  getDataForEdit() {
    this.isEditing
    if (!this.editId) return;
    this.isEditing = true;
    //call API for get job data here
    this.isLoading = true;

    this.api.GET(`${API_URLS.SUBMIT_POOLS}/${this.editId}`).subscribe(
      (response) => {
        let data = response.data || {};
        this.model = data;
        this.vehicleTypes = data?.vehicleTypes;
        this.vehicleSelectOptions = data?.options || [];
        this.subjectType = data.subjectType;
        if (this.model.isDedicatedPool) {
          this.formGroupDeclaration.dedicatedInfo = this.getDeclarationForDedicatedInfo();
        }
        data = this.initPoolType(data)
        data = this.formatDedicatedInfo(data)
        this.createFormInput(data);

        this.isLoading = false;
      },
      (err) => {
        this.setEnableFormGroup(true);
      }
    );
  }

  protected beforeBindModel(model: any) {
    let lanes = model.dedicatedInfo?.lanes || []
    if (Utils.isArrayNotEmpty(lanes)) {
      for (let item of model.dedicatedInfo['lanes']) {
        for (let k of ['from', 'to']) {
          item[k] = item[k].zipcode;
        }
      }
    }
    return model
  }

  getFormData_JSON() {
    let data = super.getFormData_JSON(true);
    return data;
  }

  onBtnSave() {
    let json: any = this.getFormData_JSON();
    json.vehicleTypes = this.vehicleTypes
    json.options = this.vehicleSelectOptions
    if (json.isDedicatedPool) {
      json.dedicatedInfo.lanes = json.dedicatedInfo.lanes.map(item => {
        return { from: { zipcode: item.from }, to: { zipcode: item.to } }
      })

      if(typeof json?.dedicatedInfo?.clientIds === 'string'){
        json.dedicatedInfo.clientIds = [json?.dedicatedInfo?.clientIds]
      }
    }
    //convert data before submit

    this.setEnableFormGroup(false);
    this.startProgress();

    if (this.editId) {
      this.api.PUT(`${API_URLS.SUBMIT_POOLS}/${this.editId}`, json).subscribe(
        (response) => {
          this.stopProgress();
          this.showSuccess('Pool has been updated successfully.')
          this.location.back()
        },
        (err) => {
          this.showErr(err);
          this.stopProgress();
          this.setEnableFormGroup(true);
        }
      );
    }
    else {
      this.api.POST(API_URLS.SUBMIT_POOLS, json).subscribe(
        (response) => {
          this.stopProgress();
          this.showSuccess('Pool has been created successfully.')
          this.router.navigate([this.routeAdminPools, response.data.id], { replaceUrl: true });

        },
        (err) => {
          this.showErr(err);
          this.stopProgress();
          this.setEnableFormGroup(true);
        }
      );
    }
  }

  doCancel(): any {
    this.location.back()
  }

  doRemove() {
    this.api.DELETE(`${API_URLS.SUBMIT_POOLS}/${this.editId}`).subscribe(
      (response) => {
        this.stopProgress();
        this.showSuccess('Pool has been deleted successfully.')
        this.location.back()
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
        this.setEnableFormGroup(true);
      }
    );
  }

  onBtnRemove() {
    DialogService.confirmDeletion({
      message: `Are you sure you want to remove these pools?`,
      fnOk: () => this.doRemove(),
      txtBtnOk: 'Remove'
    })
  }

  onChangeAutoAddCarrier(e) {
    if (e) {
      let coverageAreas = this.formInput.get('coverageAreas').value;
      let vehicleTypes = this.formInput.get('vehicleTypes').value;
      if (!coverageAreas?.length) return;
      this.isAutoLoading = true
      this.api.POST(`${Const.APIV2(Const.APIURI_POOLS)}/get-carrier-by-areas`, {
        equipments: vehicleTypes ?? [],
        poolAreas: coverageAreas
      }).subscribe(
        (response) => {
          this.isAutoLoading = false
          let carriers = response?.data?.list_data ?? []
          let carrierIds = carriers.map(item => item.id)
          this.transfer_carrier.value = carrierIds
          this.showSuccess('Auto add carrier successfully.')
        },
        (err) => {
          this.isAutoLoading = false
          this.showErr(err);
        }
      );
      // get-carrier-by-areas
    }
  }

  get isDedicatedPoolSelected() {
    return this.isFormControlExists('dedicatedInfo');
  }

  toggleIsDedicated(value: boolean) {
    const key = 'dedicatedInfo';
    if (value) {
      this.addItemToFormGroup(key, this.getDeclarationForDedicatedInfo(), this.model?.[key]);
    } else {
      this.removeFormGroupItem(key);
    }
  }

  private getDeclarationForDedicatedInfo(): FormControlDeclaration {
    return { label: 'Dedicated for', required: true, type: 'formGroup', childItem: FormCarrierDedicatedInfo.declaration }
  }

  private resetField() {
    this.formInput.get('isDedicatedPool').setValue(false)
    this.formInput.get('autoAddCarrier').setValue(false)
    this.toggleIsDedicated(false)
  }

  public syncCarriersToPool(){
    this.onChangeAutoAddCarrier(true)
  }

  public getPlaceHolder(key: string){
    if(key === 'description' && this.isDedicatedPoolSelected){
      return 'City, State to City, State / Carrier Name (Exp: New York, NY to Orlando, FL / Felix Trucking 4)'
    }
    if(key === 'name' && this.isDedicatedPoolSelected){
      return 'Customer Name, State to State (Exp: Gobolt PA to FL)'
    }
    return super.getPlaceHolder(key)
  }

  public onChangeType(e) {
    this.resetField()
    if (e === 'autoPool') {
      this.formInput.get('autoAddCarrier').setValue(true)
    }
    if (e === 'dedicated') {
      this.formInput.get('isDedicatedPool').setValue(true)
      this.formInput.get('autoAddCarrier').setValue(false)
      this.toggleIsDedicated(true)
      this.setLanesRequire()
    }
  }
}
