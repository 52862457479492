import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { DataorchService } from "@services/dataorch.service";
import { PlanningService } from "@services/planning.service";
import _ from "underscore";

@Component({
    selector: '[bid-card]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class BidCard extends BaseComponent {
    dataorch: DataorchService
    planning: PlanningService
    _bid: any = null
    _job: any = null
    carriers: any[] = []
    shownCarriers: any[] = []
    showAll: boolean = false

    _data: {
        id?: string,
        bid?: any,
        job?: any
    } = {}
    loading: boolean = false
    accepted: any = null
    placedCount: number = 0

    @Input() style: string = null
    onBidAccepted: any = null

    @Input() set data(v: { id?: string, job?: any, bid?: any[] }) {
        this._data = v
        this.job = v.job

        if (v.bid) {
            this.bid = v.bid
        } else if (v.id) {
            this.loadBid()
        }
    }

    set bid(v) {
        this._bid = v
        this.accepted = v.carriers.filter(it => it.carrierId == this._job?.assignedCarrier?.carrierId)[0]
        const placedBids = _.sortBy(v.carriers.filter(it => it.state && it.state > 1), 'price')
        const refused = v.carriers.filter(it => it.state == 1)
        const waitingResponse = v.carriers.filter(it => !it.state)
        this.placedCount = placedBids.length
        this.carriers = placedBids.concat(refused).concat(waitingResponse)
        this.processShowCarriers()

        if (!this._job && v.jobId) {
            this.dataorch.getJob(v.jobId).subscribe((res) => {
                this.job = res
            })
        }
    }
    get bid() {
        return this._bid
    }

    set job(v) {
        this._job = v
        this.accepted = this.bid ? this.bid.carriers?.filter(it => it.carrierId == this._job?.assignedCarrier?.carrierId)[0] : null
    }
    get job() {
        return this._job
    }

    processShowCarriers() {
        if (this.showAll) {
            this.shownCarriers = this.carriers
        } else {
            this.shownCarriers = this.carriers.filter(it => it.state && it.state > 1)
        }
    }

    toggleShowCarriers() {
        this.showAll = !this.showAll
        this.processShowCarriers()
    }

    loadingBid = false
    async loadBid() {
        // const bid = this.dataorch.loadBid(this._data.id)
        this.loadingBid = true
        const bid = await this.dataorch.loadBid(this._data.id).toPromise()
        this.bid = bid
        this.loadingBid = false
    }

    onBtnAccept(carrier) {
        let modalRef: any = null
        modalRef = this.modalService.create({
            nzTitle: `Accept Bid for route ${this.bid.jobCode}`,
            nzContent: `<p>Please confirm you want to assign this route</p><p> to carrier <strong>${carrier.carrierName}</strong></p><p> at rate: <strong>$${carrier.price}</strong></p>`,
            nzOnOk: () => {
                this.assignCarrier(carrier)
                modalRef.close()
            }
        })        
    }

    async assignCarrier(offer) {
        const params = {
            jobId: this.bid.jobId,
            carrierId: offer.carrierId,
            cost: this.makeCost(offer.price)
        };
        const resp = await this.api.POST(`${Const.APIURI_CARRIER_BIDS}/accept-bid`, params).toPromise().catch(err => {
            this.showErr(err);
            console.log(" error: ", err);
        });

        if (resp) {
            //   this.onBtnRefresh();
            this.showInfo(`Carrier has been assigned successfully.`);
            this.dataorch.getJob(this.bid.jobId).subscribe((res) => {
                this.job = res
                this.onBidAccepted && this.onBidAccepted({bid: this._bid, job: this._job, offer})
            })
        }
    }

    private makeCost(price) {
        return {
            currency: {
                type: "USD",
                fxRate: null
            },
            transitCost: {
                rate: price,
                qty: 1,
                total: price
            },
            volumeDiscount: {
                type: "percentage",
                percentage: null,
                flatRate: null,
                qty: null,
                total: 0
            },
            subTotal: price,
            fuelCost: {
                type: "rpm",
                percentage: null,
                rpm: null,
                qty: null,
                total: 0
            },
            serviceOptions: [],
            negativeMarginReason: null,
            manager: null,
            grandTotal: price,
            usdConversion: 0
        }
    }

    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)
        this.planning = new PlanningService(this.api)
    }
}