import {Component, EventEmitter, Output} from "@angular/core";
import {BaseFormItem} from "@app/admin/base/form-item";
import {ActivatedRoute} from "@angular/router";
import {Const} from "@const/Const";
import {forkJoin} from "rxjs";

@Component({
  selector: '[carrier-pool-filter]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss', '../../../../app.scss', '../../../../dialogs/dialogs.scss']
})
export class CarrierPoolFilter extends BaseFormItem {
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();

  public static get declaration(): FormGroupDeclaration {
    return {
      searchKeyword: {label: 'Keyword', placeHolder: 'Search by name', notAcceptEmpty: true},
      isDedicated: {label: 'Dedicated', notAcceptEmpty: true, placeHolder: 'Select from list'},
      vehicleTypes: {label: 'Vehicle', notAcceptEmpty: true, placeHolder: 'Select from list'},
      areaIds: {label: 'Area', notAcceptEmpty: true, placeHolder: 'Select from list'},
      carriers: {label: 'Carrier', notAcceptEmpty: true, placeHolder: 'Select from list'},
    }
  };

  protected formGroupDeclaration: FormGroupDeclaration = CarrierPoolFilter.declaration;

  public condition: any = {}
  public searchKeyword: string = null
  public loaded: number = 0
  public page: number = 1
  listDedicated = [
    {id: "yes", name: "TRUE"},
    {id: "no", name: "FALSE"},
  ];
  listVehicles = [];
  listAreas = [];
  listCarriers = [];

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.activatedRoute.queryParams.subscribe(p => {
      if (p.filter) {
        this.condition = JSON.parse(p.filter)
      }
      this.searchKeyword = p.searchKeyword
      this.loaded = p.loaded || 0
      this.page = p.page || 1
    })
  }

  doSearch(s) {
    this.searchKeyword = s
    this.page = 1
    this.loadData()
  }

  onRefresh() {
    this.onReload.emit()
    this.loadData()
  }

  loadData() {
    let q = {page: this.page || 1, loaded: Date.now()}
    if (this.searchKeyword) {
      q['search'] = this.searchKeyword
    }
    const f = JSON.stringify(this.condition)
    if (f.length > 2) {
      q['filter'] = f
    }
    this.routeWithQueryUrl(q)
  }

  onChange(e, name) {
    this.page = 1
    if (e)
      this.condition[name] = e
    else
      delete this.condition[name]
    this.loadData()
  }

  ngOnInit(): void {
    this.model = Object.assign({}, this.condition, {search: this.searchKeyword})
    super.ngOnInit();
    this.fetchSelections();
  }

  public filterLoading = false;

  private fetchSelections() {
    const urlPools = Const.APIV2(`${Const.APIURI_POOLS}/summary/carrier-pools`);
    const urlCarriers = `${Const.APIURI_CARRIERS}/list/all_for_filter`;

    this.filterLoading = true;

    forkJoin([
      this.api.GET(urlCarriers),
      this.api.GET(urlPools)
    ]).subscribe(
      ([carrierResp, selectionResp]) => {
        this.listCarriers = carrierResp.data?.list_data || [];
        this.listVehicles = selectionResp.data?.vehicles || [];
        this.listAreas = selectionResp.data?.areas || [];
      },
      (error) => {
        this.showErr(error);
      },
      () => {
        this.filterLoading = false;
      }
    );
  }

  getVehicleName(item: any) {
    let str = item?.name || item.id;
    let parentName = item?.parentName || item?.parent || "";
    if (parentName) str = `${str} (${parentName})`;
    return str
  }

  getAreaName(item: any) {
    return item?.name || ""
  }
}
