<div>
    <div>Shipment Ids</div>
    <div class="flex bottom10">
        <div class="flex1 right10">
            <input [disabled]="adding || loading" nz-input [(ngModel)]="shipmentIds" (keypress)="onInputKeyPress($event)">
        </div>
        <div>
            <button style="width: 100px;" [disabled]="!shipmentIds || loading" nzType="primary" nz-button (click)="onShipmentIdsUpdate()">
                <i nz-icon nzType="loading" nzTheme="outline" *ngIf="loading"></i>
                Load
            </button>
        </div>
    </div>
    <div *ngIf="shipments?.length">
        <nz-table #basicTable [nzData]="shipments" nzSize="small">
            <thead>
                <tr>
                    <th nzWidth="20px"></th>
                    <th nzWidth="80px">Id</th>
                    <th>status</th>
                    <th>Pickup</th>
                    <th>Pickup Window</th>
                    <th>Delivery</th>
                    <th>Delivery Window</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of basicTable.data" [class]="data.issue ? 'INVALID' : 'VALID'">
                    <td>
                        <span nz-icon nzType="delete" nzTheme="outline" class="clickable" (click)="removeShipment(data.id)"></span>
                    </td>
                    <td>
                        <i nz-icon nzType="loading" nzTheme="outline" *ngIf="data.addingStatus=='ADDING'"></i>
                        <i nz-icon nzType="check" style="color: green;" nzTheme="outline" *ngIf="data.addingStatus=='ADDED'"></i>
                        <i nz-icon nzType="warning" style="color: red;" nzTheme="outline" *ngIf="data.addingStatus=='ERROR'"></i>
                        {{ data.warpId }}
                    </td>
                    <td>
                        <span *ngIf="data.issue" style="color: red;" nz-icon nzType="warning" nzTheme="outline"
                            nz-popover nzPopoverTitle="Validation Errror" [nzPopoverContent]="data.issue"
                        >
                        </span>
                        <span *ngIf="!data.issue" style="color: green;" nz-icon nzType="check" nzTheme="outline"></span>
                    </td>
                    <td>
                        {{ data.pickup.addr.state }} {{ data.pickup.addr.zipcode }}
                    </td>
                    <td>{{data.pickupWindow}}</td>
                    <td>
                        {{ data.dropoff.addr.state }} {{ data.dropoff.addr.zipcode }}
                    </td>
                    <td>{{data.dropoffWindow}}</td>
                </tr>
            </tbody>
        </nz-table>

        <div>
            <button [disabled]="!canAdd" nz-button (click)="onAddBtn()">Add To Planning</button>
        </div>
    </div>
</div>