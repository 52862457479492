import { BaseList } from "../../base/list";
import { Component } from "@angular/core";
import { Const } from "@app/const/Const";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from '@services/api.service';

@Component({
  selector: "carrier-pool-list",
  templateUrl: "./list.html",
  styleUrls: ["../../list.scss"],
})
export class CarrierPoolList extends BaseList {
  constructor(protected activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected getApiUrl(): string {
    return Const.APIV2(Const.APIURI_POOLS);
  }

  onBtnCreate() {
    this.router.navigate([this.routeAdminPools, 'create', 'carrier'])
  }

  getDefaultFilter = () => {
    return { subjectType: 'carrier' }
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    if (!this.queryParams.filter && !this.queryParams.search) {
      let filter = JSON.stringify(this.getDefaultFilter());

      return this.routeWithQueryUrl({ filter });
    }
    super.handleNavigationEnd(url, prevQueryParam);

  }

  public isExporting = false;
  onBtnExport() {
    this.isExporting = true;
    let query = this.prepareParamGetList();
    query.isDownload = true;
    this.api.postExport(Const.APIV2(`${Const.APIURI_POOLS}/export`), query).subscribe(
      resp => {
        ApiService.handleDownloadResponse(resp);
        this.isExporting = false;
      }, err => {
        this.showErr(err);
        this.isExporting = false;
      }
    );
  }

  getEquipments(item){
    let vehicleObjTypes = item?.vehicleObjTypes ?? []
    let result = vehicleObjTypes.map(v => v.name)
    result = result.join(', ')
    return result;
  }
}
