import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { DataorchService } from "@services/dataorch.service";
import _ from "underscore";

@Component({
    selector: '[linehaul-lane-detail]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class LinehaulLaneDetail extends BaseComponent {
    dataorch: DataorchService
    routeAdminCrossdockWarehouseJob = Const.routeAdminCrossdockWarehouseJob
    _data: {
        id?: string,
        lane?: any
    } = {}
    loading: boolean = false

    @Input() set data(v: {id?: string, lane?: any}) {
        this._data = v
        if (!this._data.lane && v.id) {
            this.loadLane(v.id)
        }
    }

    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)
    }

    loadLane(id) {
        this.loading = true
        this.dataorch.getLinehaulLane(id).subscribe(r => {
            this.loading = false
            this._data.lane = r
        })
    }
}