import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { BizUtil } from "@services/biz";
import { PlanningService } from "@services/planning.service";
import { Utils as ServiceUtils } from "@services/utils";
import { UpdateShipmentReviewStatusForm } from "../../update-review-status";
import { MasterData } from "@services/master.data";
import { ModalHelper, UpdateTimeWindows } from "@wearewarp/ng-antd";
import { UIHelper } from "@services/UIHelper";

@Component({
    selector: '[need-routing-shipment-list]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class NeedRoutingShipmentList extends BaseComponent {
    _shipments: any[] = null
    planned: any[] = null
    pendingAppt: any[] = null
    unplanned: any[] = null
    service: PlanningService
    filterError: string = null
    filteredCount: any = null
    checked: boolean = false
    indeterminate: boolean = false
    setOfCheckedId = new Set<string>();
    routePlanningSessions = Const.routePlanningSessions

    get shipments() {
        return this._shipments
    }
    @Input() set shipments(v) {
        this._shipments = v.map(this.processShipment)
        this.planned = this._shipments.filter(it => it.session?.id)
        const apptRequired = (it) => (it.dropoff.requiresAppointment && !it.dropoff.appointmentInfo?.from) || (it.pickup.requiresAppointment && !it.pickup.appointmentInfo?.from)
        this.pendingAppt = this._shipments.filter(it => !it.session?.id).filter(apptRequired)
        this.unplanned = this._shipments.filter(it => !it.session?.id).filter(it => !apptRequired(it))
    // update checked id
        const allIds = new Set<string>();
        for (let s of v) {
            allIds.add(s.id)
        }
        this.setOfCheckedId = new Set([...this.setOfCheckedId].filter(it => allIds.has(it)))
    }

    @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
    @Output() onPlanningAdded: EventEmitter<any> = new EventEmitter<any>();
    @Output() hoverShipment: EventEmitter<any> = new EventEmitter<any>();
    @Output() clickShipment: EventEmitter<any> = new EventEmitter<any>();
    @Output() reviewStatusUpdate: EventEmitter<any> = new EventEmitter<any>();
    @Output() deliveryInfoUpdated: EventEmitter<any> = new EventEmitter<any>();
  
    constructor(private modalHelper: ModalHelper) {
        super()
        this.service = new PlanningService(this.api)
        this.processShipment = this.processShipment.bind(this)
    }

    loading = false
    refreshCheckedStatus(): void {
        const listOfData = this._shipments.filter(it => !it.excluded)
        this.checked = listOfData.every(({ id }) => this.setOfCheckedId.has(id));
        this.indeterminate = listOfData.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
    }

    updateCheckedSet(id: string, checked: boolean): void {
        if (checked) {
            this.setOfCheckedId.add(id);
        } else {
            this.setOfCheckedId.delete(id);
        }
    }

    onItemChecked(id: string, checked: boolean): void {
        this.updateCheckedSet(id, checked);
    }

    onAllChecked(checked: boolean): void {
        this._shipments
          .forEach(({ id }) => this.updateCheckedSet(id, checked));
    }

    processShipment(shipment) {
        const pickup = BizUtil.getPickInfo(shipment)
        const dropoff = BizUtil.getDropInfo(shipment)

        const { metadata } = shipment || {}
        const { mile, issue } = metadata || {}
        const transitType = shipment.shipmentTransitType == 'none' ? 'main' : shipment.shipmentTransitType

        return {
            id: shipment.id,
            warpId: shipment.warpId,
            excluded: shipment.excluded,
            clientName: shipment.client?.name || shipment.metadata?.client?.name,
            pickup,
            dropoff,
            pickupWindow: pickup ? BizUtil.getDeliveryInfoTime(pickup, {showWindow: true, format: 'M/D h:mm A', formatDateOnly: 'M/D'}) || '-' : '-',
            dropoffWindow: dropoff ? BizUtil.getDeliveryInfoTime(dropoff, {showWindow: true, format: 'M/D h:mm A', formatDateOnly: 'M/D'}) || '-' : '-',
            issue,
            orderId: shipment.order?.id,
            parentId: shipment.metadata?.parent?.id,
            orderWarpId: shipment.order?.warpId,
            reviewStatus: shipment.review?.status,
            transitType: transitType  ? ((mile ? mile + ' ' : '')  + transitType) : 'main',
            readiness: shipment.metadata.readiness,
            session: shipment.metadata.session
        }
    }

    canAdd: boolean = false
    checkCanAdd() {
        this.canAdd = this.shipments?.length && this.shipments.filter(it => it.issue).length < 1
    }

    async onAddBtn() {
        for (let shipment of this.shipments) {
            shipment.addingStatus = 'ADDING'
            await this.service.addShipmentToPlanning(shipment.id).subscribe((res) => {
                shipment.addingStatus = 'ADDED'
                shipment.session = {
                    id: res.sessionId
                }
                this.onPlanningAdded.emit(shipment)
            }, (err) => {
                shipment.addingStatus = 'ERROR'
            })
        }
    }

    removeShipment(event, id) {
        event.stopPropagation()
        let removed = false
        this._shipments.filter(it => it.id === id).map((r) => {
            r.excluded = !r.excluded
            removed = r.excluded
        })
        this.checkCanAdd()

        this.onRemove.emit({id, removed})
    }

    addToPlanning(event, id) {
        event.stopPropagation()
        const shipment = this._shipments.filter(it => it.id === id)[0]
        if (!shipment) return
        shipment.addingStatus = 'ADDING'
        const apptRequired = (it) => (it.dropoff.requiresAppointment && !it.dropoff.appointmentInfo?.from) || (it.pickup.requiresAppointment && !it.pickup.appointmentInfo?.from)
        this.service.addShipmentToPlanning(id).subscribe((res) => {
            shipment.addingStatus = 'ADDED'
            shipment.session = {
                id: res.sessionId,
            }
            this.planned = this._shipments.filter(it => it.session?.id)
            this.pendingAppt = this._shipments.filter(it => !it.session?.id).filter(apptRequired)
            this.unplanned = this._shipments.filter(it => !it.session?.id).filter(it => !apptRequired(it))
            this.onPlanningAdded.emit(shipment)
        }, (err) => {
            shipment.addingStatus = 'ERROR'
        })

    }

    onCopyId(event, id) {
        event.stopPropagation()
        if (!id) return
        ServiceUtils.copyTextToClipboard(id.toString(), (e) => {
            if (e) {
                this.showErr("Cannot copy to clipboard");
            } else {
                this.showSuccess(
                    `Copied ${id} to the clipboard`
                );
            }
        })  
    }
    onCopyIds() {
        const ids = this._shipments.filter(it => this.setOfCheckedId.has(it.id)).filter(it => it.warpId).filter(it => !it.excluded).map(it => it.warpId.toString())
        ServiceUtils.copyTextToClipboard(ids.join(", "), (e) => {
            if (e) {
                this.showErr("Cannot copy to clipboard");
            } else {
                this.showSuccess(
                    `Copied ${ids.length} ids to the clipboard`
                );
            }
        })    
    }

    updateShipmentReviewStatus(event, shipment) {
        event.stopPropagation()
        this.modalService.create({
            nzTitle: `Update Review Status for shipment ${shipment.warpId}`,
            nzContent: UpdateShipmentReviewStatusForm,
            nzWidth: "800px",
            nzComponentParams: {
                id: shipment.id,
                warpId: shipment.warpId,
                status: shipment.reviewStatus || {}
            },
            nzOnOk: (comp) => {
                comp.onSave().subscribe((res) => {
                    const { review } = res.data
                    const { status } = review
                    shipment.reviewStatus = status
                    this.reviewStatusUpdate.emit(res.data)
                }, (err) => {
                    this.showErr(err)
                })
            },
            nzOnCancel: (comp) => {
                comp.onCancel()
            }
        })
    }
    
    onBtnEditWindowsTime(shipment, deliveryInfo) {
        UpdateTimeWindows.openModal(this.modalHelper, {
            onSubmitError: err => UIHelper.showErr(err),
            onSubmitSucceeded: res => {
                const s = this._shipments.filter(it => it.id === shipment.id)[0]
                if (!s) return
                if (deliveryInfo.type === 'PICKUP') {
                    s.pickup = res.data
                    s.pickupWindow = BizUtil.getDeliveryInfoTime(res.data, {showWindow: true, format: 'M/D h:mm A', formatDateOnly: 'M/D'}) || '-'
                } else {
                    s.dropoff = res.data
                    s.dropoffWindow = BizUtil.getDeliveryInfoTime(res.data, {showWindow: true, format: 'M/D h:mm A', formatDateOnly: 'M/D'}) || '-'
                }
                this.deliveryInfoUpdated.emit({id: shipment.id, deliveryInfo: res.data})
            },
            nzTitle: `Shipment ${shipment.warpId} ${deliveryInfo.type} Time Windows`,
            nzComponentParams: {
            timezone: deliveryInfo.addr?.metadata?.timeZoneStandard,
            model: {
                windows: deliveryInfo.windows,
                reasonCodeId: deliveryInfo.reasonCodeId,
            },
            reasonCodes: MasterData.getChangeDateTimeReasons(),
            submit: data => this.updateDeliveryInfo(shipment.id, deliveryInfo.id, data),
            }
        });
    }

    private updateDeliveryInfo(shipmentId, deliveryInfoId, data) {
        const url = Const.APIV2(`shipments/${shipmentId}/delivery-info/${deliveryInfoId}`)
        return this.api.PUT(url, data)
    }

    readinessColors = ['red', 'black', 'gray', 'orange', 'blue', 'green']
}